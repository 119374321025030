import validator from 'cpf-cnpj-validator'
import Joi from 'joi'

const JoiExtended = Joi.extend(validator)
const partnerCreate = Joi.array().items({
  name: JoiExtended.string().required().messages({
    'any.required': 'O "nome do sócio" é obrigatório',
    'string.empty': 'O "nome do sócio" não pode ser vazio'
  }),
  email: JoiExtended.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'any.required': 'O "email" é obrigatória',
      'string.empty': 'O "email" não pode ser vazio',
      'string.email': 'O "email" não é válido'
    }),
  qual: JoiExtended.string().allow('').optional()
})

const initialDate = Joi.date().min(new Date()).messages({
  '': 'A "Previsão de Arbitragem" é obrigatório'
})

const boardDirectors = Joi.array().items({
  name: JoiExtended.string().required().messages({
    'any.required': 'O "nome do sócio" é obrigatório',
    'string.empty': 'O "nome do sócio" não pode ser vazio'
  }),
  email: JoiExtended.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'any.required': 'O "email" é obrigatória',
      'string.empty': 'O "email" não pode ser vazio',
      'string.email': 'O "email" não é válido'
    }),
  document: JoiExtended.document().cpf().required().messages({
    'any.required': 'O "CPF" é obrigatório',
    'string.empty': 'O "CPF" não pode ser vazio',
    'any.cpf': 'O "CPF" é inválido'
  })
})

const preferredByClasses = Joi.array().items({
  name: JoiExtended.string().required().messages({
    'any.required': 'O "nome" é obrigatório',
    'string.empty': 'O "nome" não pode ser vazio'
  }),
  value: JoiExtended.number().allow(0).optional(),
  quantity: JoiExtended.number().allow(0)
})

const partnersQuota = Joi.array().items({
  name: JoiExtended.string().required().messages({
    'any.required': 'O "nome" é obrigatório',
    'string.empty': 'O "nome" não pode ser vazio'
  }),
  quota: JoiExtended.number().required().messages({
    'any.required': 'A "quota" é obrigatória',
    'number.empty': 'A "quota" não pode ser vazia'
  })
})

const partner = {
  user: {
    name: JoiExtended.string().required().messages({
      'any.required': 'O "nome" é obrigatório',
      'string.empty': 'O "nome" não pode ser vazio'
    }),
    email: JoiExtended.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        'any.required': 'O "email" é obrigatória',
        'string.empty': 'O "email" não pode ser vazio',
        'string.email': 'O "email" não é válido'
      }),
    emailUpdated: JoiExtended.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        'any.required': 'O "email" é obrigatória',
        'string.empty': 'O "email" não pode ser vazio',
        'string.email': 'O "email" não é válido'
      }),
    document: JoiExtended.document().cpf().allow('').optional(),
    nationality: JoiExtended.string().allow('').optional(),
    maritalStatus: JoiExtended.string().allow('').optional(),
    profession: JoiExtended.string().allow('').optional(),
    address: JoiExtended.string().allow('').optional(),
    number: JoiExtended.string().allow('').optional(),
    complement: JoiExtended.string().allow('').optional(),
    zipCode: JoiExtended.string().allow('').optional(),
    neighborhood: JoiExtended.string().allow('').optional(),
    state: JoiExtended.string().allow('').optional(),
    city: JoiExtended.string().allow('').optional(),
    generalRegistry: JoiExtended.string().allow('').optional(),
    birthday: JoiExtended.string().allow('').optional(),
    phoneNumber: JoiExtended.string().allow('').optional()
  }
}

const fieldsUserUpdateValidations = {
  email: JoiExtended.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'any.required': 'O "email" é obrigatória',
      'string.empty': 'O "email" não pode ser vazio',
      'string.email': 'O "email" não é válido'
    }),
  password: JoiExtended.string().optional(),
  currentPassword: JoiExtended.string().required().messages({
    'string.required': 'A "senha atual" é obrigatória'
  }),
  name: JoiExtended.string().required().messages({
    'any.required': 'O "nome" é obrigatória',
    'string.empty': 'O "nome" não pode ser vazia'
  })
}

const fieldsUserValidations = {
  email: JoiExtended.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'any.required': 'O "email" é obrigatória',
      'string.empty': 'O "email" não pode ser vazio',
      'string.email': 'O "email" não é válido'
    }),
  confirmPassword: JoiExtended.string()
    .valid(JoiExtended.ref('password'))
    .required()
    .messages({
      'any.only': 'O "confirmar senha" não é igual a "senha"',
      'any.empty': 'O "confirmar senha" não pode ser vazia'
    }),
  password: JoiExtended.string().required().messages({
    'any.required': 'A "senha" é obrigatória',
    'string.empty': 'A "senha" não pode ser vazia'
  }),
  name: JoiExtended.string().required().messages({
    'any.required': 'O "nome" é obrigatória',
    'string.empty': 'O "nome" não pode ser vazia'
  })
}

const fieldsValidations = {
  username: JoiExtended.string().min(5).required(),
  email: JoiExtended.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'any.required': 'O "email" é obrigatória',
      'string.empty': 'O "email" não pode ser vazio',
      'string.email': 'O "email" não é válido'
    }),
  password: JoiExtended.string().required().messages({
    'any.required': 'A "senha" é obrigatória',
    'string.empty': 'A "senha" não pode ser vazia'
  }),
  name: JoiExtended.string().required().messages({
    'any.required': 'A "razão social" é obrigatória',
    'string.empty': 'A "razão social" não pode ser vazia'
  }),
  trading_name: JoiExtended.string().required().messages({
    'any.required': 'O "nome fantasia" é obrigatório',
    'string.empty': 'O "nome fantasia" não pode ser vazio'
  }),
  tradingName: JoiExtended.string().required().messages({
    'any.required': 'O "nome fantasia" é obrigatório',
    'string.empty': 'O "nome fantasia" não pode ser vazio'
  }),
  address: JoiExtended.string().required().messages({
    'any.required': 'O "endereço" é obrigatório',
    'string.empty': 'O "endereço" não pode ser vazio'
  }),
  activity: JoiExtended.string().required().messages({
    'any.required': 'A "atividade" é obrigatória',
    'string.empty': 'A "atividade" não pode ser vazia'
  }),
  type: JoiExtended.string().required().messages({
    'any.required': 'O "tipo de contrato" é obrigatório',
    'string.empty': 'O "tipo de contrato" não pode ser vazio'
  }),
  number: JoiExtended.string().required().messages({
    'any.required': 'O "número" é obrigatório',
    'string.empty': 'O "número" não pode ser vazio'
  }),
  complement: JoiExtended.string().optional().allow(''),
  deadlineOn: JoiExtended.string().optional().allow(''),
  zipCode: JoiExtended.string().required().messages({
    'any.required': 'O "CEP" é obrigatório',
    'string.empty': 'O "CEP" não pode ser vazio'
  }),
  neighborhood: JoiExtended.string().required().messages({
    'any.required': 'O "bairro" é obrigatório',
    'string.empty': 'O "bairro" não pode ser vazio'
  }),
  state: JoiExtended.string().required().messages({
    'any.required': 'O "estado" é obrigatório',
    'string.empty': 'O "estado" não pode ser vazio'
  }),
  city: JoiExtended.string().required().messages({
    'any.required': 'A "cidade" é obrigatória',
    'string.empty': 'A "cidade" não pode ser vazia'
  }),
  expectedDate: JoiExtended.string().required().messages({
    'any.required': 'A "data prevista da constituição" é obrigatória',
    'string.empty': 'A "data prevista da constituição" não pode ser vazia'
  }),
  logo: JoiExtended.any().optional(),
  cnpj: JoiExtended.string().optional().allow(''),
  opening: JoiExtended.any().optional(),
  secondaryActivities: JoiExtended.array().optional(),
  phoneNumber: JoiExtended.string().optional().allow(''),
  confirmPassword: JoiExtended.string()
    .valid(JoiExtended.ref('password'))
    .required()
    .messages({ 'any.only': 'O "confirmar senha" não é igual a "senha"' })
}

const documentFieldsValidations = {
  document: JoiExtended.object().required().messages({
    'any.required': 'A "razão social" é obrigatória',
    'string.empty': 'A "razão social" não pode ser vazia'
  }),
  documentDate: JoiExtended.string().required().messages({
    'any.required': 'A "data de criação" é obrigatória',
    'string.empty': 'A "data de criação" não pode ser vazia'
  }),
  fileType: JoiExtended.string().required().messages({
    'any.required': 'O "tipo de contrato" é obrigatório',
    'string.empty': 'O "tipo de contrato" não pode ser vazio'
  })
}

const eventFieldsValidations = {
  type: JoiExtended.string().required().messages({
    'any.required': 'O "tipo" é obrigatório',
    'string.empty': 'O "tipo" não pode ser vazio'
  }),
  eventType: JoiExtended.string().required().messages({
    'any.required': 'O "tipo de evento" é obrigatório',
    'string.empty': 'O "tipo de evento" não pode ser vazio'
  }),
  date: JoiExtended.string().required().messages({
    'any.required': 'A "data" é obrigatória',
    'string.empty': 'A "data" não pode ser vazia'
  }),
  hour: JoiExtended.optional(),
  local: JoiExtended.optional(),
  dayOrder: JoiExtended.array().optional(),
  participants: JoiExtended.optional(),
  isDocsAvailable: JoiExtended.optional(),
  isEditalPreviousPublished: JoiExtended.optional(),
  isPendingsIssuesResolved: JoiExtended.optional(),
  observation: JoiExtended.optional().allow('')
}

const conflictResolutionWithPrediction = {
  arbitrationChamber: JoiExtended.string().required().messages({
    'any.required': 'A "Câmara de Arbitragem" é obrigatória',
    'string.empty': 'A "Câmara de Arbitragem" não pode ser vazia'
  }),
  city: JoiExtended.string().optional().allow(''),
  state: JoiExtended.string().optional().allow(''),
  arbitragePrediction: JoiExtended.boolean().required().messages({
    'any.required': 'A "Previsão de Arbitragem" é obrigatório',
    'string.empty': 'A "Previsão de Arbitragem" não pode ser vazia'
  })
}

const conflictResolutionWithoutPrediction = {
  arbitrationChamber: JoiExtended.string().optional().allow(''),
  city: JoiExtended.string().optional().allow(''),
  state: JoiExtended.string().optional().allow(''),
  arbitragePrediction: JoiExtended.boolean().optional().messages({
    'any.required': 'A "Previsão de Arbitragem" é obrigatório',
    'string.empty': 'A "Previsão de Arbitragem" não pode ser vazia'
  })
}

type SignInValues = {
  email: string
  password: string
}

type RegisterValues = {
  name: string
  email: string
  password: string
  confirmPassword: string
}

type UpdateUserValues = {
  name?: string
  email?: string
  password?: string
  currentPassword?: string
}

export const Status: Record<string, string> = {
  pre_registered: 'PRÉ-CADASTRADO',
  registered: 'REGISTRANDO',
  processing: 'PROCESSANDO',
  active: 'ATIVO',
  awaiting_external_update: 'AGUARDANDO ATUALIZAÇÃO EXTERNA',
  '': ''
}

export const EventStatus: Record<string, string> = {
  pending: 'PENDENTE',
  ready_for_convocation: 'PRONTO PARA CONVOCAÇÃO',
  confirmed: 'CONFIRMADO',
  finished: 'REALIZADO',
  cancelled: 'CANCELADO',
  '': ''
}

export const EventType: Record<string, string> = {
  board: 'CONSELHO',
  general_meeting: 'ASSEMBLEIA GERAL'
}

export const EType: Record<string, string> = {
  ordinary: 'ORDINÁRIA',
  extraordinary: 'EXTRAORDINÁRIA',
  both: 'ORDINÁRIA E EXTRAORDINÁRIA'
}

export type CompanyFields = {
  id?: string
  name: string
  tradingName?: string
  address: string
  activity: string
  status?: string
  type: string
  number: string
  complement: string
  zipCode: string
  neighborhood: string
  state: string
  city: string
  expectedDate: string
  cnpj?: string
  opening?: string
  secondaryActivities?: Array<string>
  email?: string
  phoneNumber?: string
  logo?: any
  deadlineOn?: string
}

export type PartnerFields = {
  user: {
    id?: string | undefined
    name?: string
    email?: string
    nationality?: string
    maritalStatus?: string
    profession?: string
    address?: string
    number?: string
    complement?: string
    zipCode?: string
    neighborhood?: string
    state?: string
    city?: string
    generalRegistry?: string
    document?: string
    birthday?: string
    phoneNumber?: string
  }
}

type PartnerCreateItem = {
  name?: string
  email?: string
  qual?: string
}

type BoardDirectorCreateItem = {
  name?: string
  email?: string
  document?: string
}

type ConflictResolution = {
  arbitragePrediction?: boolean
  arbitrationChamber?: string
  city?: string
  state?: string
}

type Preferred = {
  name: string
  value?: number
  quantity: number
}

type PartnerQuotas = {
  name: string
  quota: number
}

type PartnerCreateFields = Array<PartnerCreateItem>

type BoardDirectorCreateFields = Array<BoardDirectorCreateItem>

type PartnerQuotaCreateFields = Array<PartnerQuotas>

type PreferredCreateFields = Array<Preferred>

export type DocumentsFields = {
  id: string
  document?: Record<string, unknown>
  documentDate: string
  created_at?: string
  filename?: string
  fileType?: string
  file?: string
}

export type EventFields = {
  id: string
  type?: string
  eventType?: string
  date?: string
  hour?: string
  local?: string
  dayOrder?: string[]
  isDocsAvailable?: boolean
  isEditalPreviousPublished?: boolean
  isPendingsIssuesResolved?: boolean
}

export type FieldErrors = {
  [key: string]: string
}

function getFieldErrors(objError: Joi.ValidationResult) {
  const errors: FieldErrors = {}

  if (objError.error) {
    objError.error.details.forEach((err) => {
      errors[err.path.join('.')] = err.message
    })
  }

  return errors
}

type CompanyFieldsValidate = Omit<CompanyFields, 'status' | 'id'>
export function companyValidate(values: CompanyFieldsValidate) {
  const {
    logo,
    name,
    tradingName,
    activity,
    address,
    type,
    number,
    complement,
    zipCode,
    neighborhood,
    state,
    city,
    expectedDate,
    cnpj,
    opening,
    secondaryActivities,
    email,
    phoneNumber,
    deadlineOn
  } = fieldsValidations
  const schema = JoiExtended.object({
    logo,
    name,
    tradingName,
    activity,
    address,
    type,
    number,
    complement,
    zipCode,
    neighborhood,
    state,
    city,
    expectedDate,
    cnpj,
    opening,
    secondaryActivities,
    email,
    phoneNumber,
    deadlineOn
  })
  return getFieldErrors(schema.validate(values, { abortEarly: false }))
}

type PartnerFieldsValidate = Omit<PartnerFields, 'id' | 'name'>
export function partnerValidate(values: PartnerFieldsValidate) {
  const {
    nationality,
    maritalStatus,
    profession,
    address,
    number,
    complement,
    zipCode,
    neighborhood,
    state,
    city,
    generalRegistry,
    document,
    birthday,
    phoneNumber,
    emailUpdated
  } = partner.user
  const schema = JoiExtended.object({
    user: {
      nationality,
      maritalStatus,
      profession,
      address,
      number,
      complement,
      zipCode,
      neighborhood,
      state,
      city,
      generalRegistry,
      document,
      birthday,
      phoneNumber,
      emailUpdated
    }
  })
  return getFieldErrors(schema.validate(values, { abortEarly: false }))
}

export function partnerCreateValidate(values: PartnerCreateFields) {
  return getFieldErrors(partnerCreate.validate(values, { abortEarly: false }))
}

export function boardDirectorsCreateValidate(
  values: BoardDirectorCreateFields
) {
  return getFieldErrors(boardDirectors.validate(values, { abortEarly: false }))
}

export function initialDateValidations(initialDateInput: Date) {
  return getFieldErrors(
    initialDate.validate(initialDateInput, { abortEarly: false })
  )
}

export function conflictResolutionCreateValidate(
  values: ConflictResolution,
  hasPrediction: boolean
) {
  if (hasPrediction) {
    const { city, state, arbitragePrediction, arbitrationChamber } =
      conflictResolutionWithPrediction
    const schema = JoiExtended.object({
      city,
      state,
      arbitragePrediction,
      arbitrationChamber
    })
    return getFieldErrors(schema.validate(values, { abortEarly: false }))
  }
  const { city, state, arbitragePrediction, arbitrationChamber } =
    conflictResolutionWithoutPrediction
  const schema = JoiExtended.object({
    city,
    state,
    arbitragePrediction,
    arbitrationChamber
  })
  return getFieldErrors(schema.validate(values, { abortEarly: false }))
}

export function preferrerCreateValidate(values: PreferredCreateFields) {
  return getFieldErrors(
    preferredByClasses.validate(values, { abortEarly: false })
  )
}

export function partnerQuotaCreateValidate(values: PartnerQuotaCreateFields) {
  return getFieldErrors(partnersQuota.validate(values, { abortEarly: false }))
}

type DocumentsFieldsValidate = Omit<DocumentsFields, 'id'>
export function documentValidate(values: DocumentsFieldsValidate) {
  const { document, documentDate, fileType } = documentFieldsValidations
  const schema = JoiExtended.object({
    document,
    documentDate,
    fileType
  })
  return getFieldErrors(schema.validate(values, { abortEarly: false }))
}

type EventFieldsValidate = Omit<EventFields, 'id'>
export function eventValidate(values: EventFieldsValidate) {
  const schema = JoiExtended.object(eventFieldsValidations)
  return getFieldErrors(schema.validate(values, { abortEarly: false }))
}

export function signInValidate(values: SignInValues) {
  const { email, password } = fieldsValidations
  const schema = JoiExtended.object({ email, password })

  return getFieldErrors(schema.validate(values, { abortEarly: false }))
}

export function registerValidate(values: RegisterValues) {
  const { email, password, name, confirmPassword } = fieldsUserValidations
  const schema = JoiExtended.object({ email, password, name, confirmPassword })

  return getFieldErrors(schema.validate(values, { abortEarly: false }))
}

export function userValidate(values: UpdateUserValues) {
  const { email, password, name, currentPassword } = fieldsUserUpdateValidations
  const schema = JoiExtended.object({ email, password, name, currentPassword })

  return getFieldErrors(schema.validate(values, { abortEarly: false }))
}

type ForgotValidateParams = Pick<SignInValues, 'email'>
export function forgotValidate(values: ForgotValidateParams) {
  const { email } = fieldsValidations
  const schema = JoiExtended.object({ email })

  return getFieldErrors(schema.validate(values, { abortEarly: false }))
}

type ResetValidateParams = {
  password: string
  confirmPassword: string
}

export function resetValidate(values: ResetValidateParams) {
  const { password, confirmPassword } = fieldsValidations
  const schema = JoiExtended.object({ password, confirmPassword })

  return getFieldErrors(schema.validate(values, { abortEarly: false }))
}
